import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn, isBrowser } from "../../services/auth"
class LoginForm extends React.Component {
  state = {
    location: this.props.location,
    password: ``,
    submited: false,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  
  handleSubmit = event => {
    event.preventDefault()
    this.setState({'location': this.props.location});
    this.setState({'submited': true});
    handleLogin(this.state);
  }

  render() {
    const { location, targetPath, translations } = this.props;
    const { submited } = this.state;

    if (isLoggedIn(location) && isBrowser()) {
      navigate(targetPath);
    }

    return (
      <>
        <form
          className=""
          method="post"
          onSubmit={event => this.handleSubmit(event)}
        >
          <input 
            type="hidden" 
            name="username" 
            value={location} 
          />
          <label className={`mb-8`}>
            <span className={` text-bold`}>{translations.password}</span>
            <input 
              className={`block border-2 p-1 w-full ${submited ? 'border-red-500' : 'border-black'}`}
              type="password"
              name="password"
              onChange={this.handleUpdate}
            />
          </label>
          <input className="mt-8 py-2 px-4 text-bold" type="submit" value={translations.submit} />
        </form>
      </>
    )
  }
}

LoginForm.propTypes = {
	location: PropTypes.string.isRequired,
	targetPath: PropTypes.string.isRequired,
  translations: PropTypes.object.isRequired,
}

export default LoginForm