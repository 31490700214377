import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout"
import LoginForm from "../components/communication/loginForm"
import { isLoggedIn, isBrowser } from "../services/auth"


const LoginPage = ({ pageContext }) => {
  const { t } = useTranslation(`default`)
  const { 
    globalData, 
    type,
    targetPath,
  } = pageContext;

  if (isLoggedIn(type) && isBrowser()) {
    navigate(targetPath);
  }

  return (
    <>
      <Layout { ...globalData } >
        <section className="max-w-xl mx-auto p-8 my-24 lg:my-40" >
          <h1 className="text-3xl lg:text-5xl text-bold my-8 text-center whitespace-no-wrap" >{t(`${type}`)}</h1>
          <h2 className="text-xl lg:text-3xl text-bold mb-12" >{t(`login`)}</h2>
          <LoginForm 
            location={type} 
            targetPath={targetPath} 
            translations={{ 
              password : t('password'),
              submit : t('submit'),
            }}
          />
        </section>
      </Layout>
    </>
  )
}

LoginPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default LoginPage